import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { useLanguage } from '../LanguageContext';
import Category from '../components/SideBar/Category';
import ProductCard from '../components/Product/ProductCard';
import { Loader } from '../components/Loader/Loader';
import { scrollToTop } from '../utils';
import { fetchProductsByTag, fetchTagVitrine } from '../services/products-api';
import { arrHighlights, arrOrigin } from '../constants';
import BannerInterno from '../components/Highlights/BannerInterno';

const Highlights = ({ match }) => {
    const [isLoading, setLoading] = useState(true);
    const [qtd, setQtd] = useState(0);
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsLoaded, setTagsLoaded] = useState(false);  // Estado para controlar carregamento das tags
    const [highlight, setHighlight] = useState('');
    const [originList, setOriginList] = useState([]);

    const { translations } = useLanguage();

    // Props
    const nameHighlight = match.params.name;

    // Paginate
    const [currentPage, setCurrentPage] = useState(1);
    const totalRecords = qtd;
    const recordPerPage = process.env.REACT_APP_PAGINATION_RECORD_PER_PAGE;
    const pageRange = process.env.REACT_APP_PAGINATION_PAGE_RANGE;

    const handlePageChange = (pageNumber) => {
        if (currentPage !== pageNumber) {
            setLoading(true);
            setCurrentPage(pageNumber);
        }
    };

    // Scroll
    useEffect(() => scrollToTop(), [isLoading]);

    // Load Data
    useEffect(() => {
        let origin = arrOrigin;
        setOriginList(origin);
        let tipo = arrHighlights;
        setHighlight(tipo[nameHighlight]);

        fetchProductsByTag(nameHighlight, currentPage, recordPerPage)
            .then(result => {
                if (result.data.Data) {
                    setProducts(result.data.Data.Dados);
                    setQtd(result.data.Data.QuantidadeRegistrosTotal);
                }
            })
            .finally(() => setLoading(false));
    }, [currentPage, nameHighlight, recordPerPage]);

    useEffect(() => {
        fetchTagVitrine()
            .then(result => {
                if (result.data.Codigo === 200 && result.data.Data) {
                    setTags(result.data.Data.Dados);
                    setTagsLoaded(true);
                }
            });
    }, []);

    const getTagTitleByLanguage = () => {
        const tag = tags.find(t => t.Nome === nameHighlight);    
        return tag.Descricao;
    };

    return (
        <>
            <section className="conteudo_interno conteudo_padrao container">
                <div className="filtro-sticky d-none d-lg-block">
                    <Category />
                </div>
                <div className="cards-container">
                    <div className="titulo-container">
                        {tagsLoaded ? (
                            <h4 className="titulo h2">{getTagTitleByLanguage()}</h4>
                        ) : (
                            <Loader short="true" />
                        )}
                        <p className="total-itens">Elementos Totales: <span className="total-itens_numero">{qtd}</span></p>
                    </div>

                    <BannerInterno nameHighlight={nameHighlight} />

                    <div className="cards cards-produto">
                        {!isLoading ? (
                            products.map(product =>
                                <ProductCard
                                    key={`highlight-card-${product.idProduto}`}
                                    product={product}
                                    origin={getTagTitleByLanguage()}
                                />
                            )
                        ) : (
                            <Loader short="false" />
                        )}
                    </div>
                    <nav aria-label="paginacao-promocoes">
                        {!isLoading && (
                            <Pagination
                                hideFirstLastPages
                                activePage={currentPage}
                                itemsCountPerPage={parseInt(recordPerPage)}
                                totalItemsCount={totalRecords}
                                pageRangeDisplayed={parseInt(pageRange)}
                                onChange={handlePageChange}
                                innerClass="pagination justify-content-end"
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        )}
                    </nav>
                </div>
            </section>
        </>
    );
};

export default Highlights;
