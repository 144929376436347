import React, { useState, useEffect } from 'react';
// default
import Banner from '../components/Home/Banner';
import Especiales from '../components/Home/Especiales';
import TipBar from '../components/TipBar/TipBar';
import Release from '../components/Home/Release';
import BestSeller from '../components/Home/BestSeller';
import BannerAD from '../components/Home/BannerAD';
import OwnBrand from '../components/Home/OwnBrand';
import Brands from '../components/Home/Brands';

import Modal from "react-bootstrap/Modal";
import ModalBanner from 'react-modal';
import { disconnect, setItemSession, getItemSession } from '../utils';
import { resetCart } from '../services/cart-api';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { fetchProductsByTagHome, fetchTagVitrine } from '../services/products-api';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'

import { fetchManufacturers } from '../services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from '../services/categories-api';
import { fetchVersion, fetchHomolog } from '../services/system-api';
import { fetchCartItems } from '../services/cart-api';
import { fetchMudarFilialToken } from '../services/client-api';
import { FaWindowClose } from 'react-icons/fa';
import CardBanner from '../components/Home/CardBanner';
import { fetchBanners } from '../services/banners-api';
import { useLanguage } from '../LanguageContext';

const Home = ({ refreshCartFn }) => {
    const [cookies] = useCookies(["user"]);
    const [, setCookie] = useCookies([]);
    const [mensagemBike, setMensagemBike] = useState(false);
    const [mensagemFish, setMensagemFish] = useState(false);
    const [message, setMessage] = useState([]);
    const [isOpenMessage, setIsOpenMessage] = useState(false);
    const [tags, setTags] = useState([]);

    const login = JSON.parse(getItemSession('_dados'));
    const { translations, changeLanguage } = useLanguage();

    const customStyles = {
        content: {
            position: 'absolute',
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            display: 'block',
            background: 'none',
            border: 'none',
            height: '500px',
            width: '500px',
            overflow: 'visible',
            padding: '0px',
            zIndex: 99999
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1040
        }
    };

    const showModalMessageOff = (index) => {

        var newMessage = message.filter(dado => dado.idBanner !== index)
        setMessage(newMessage)
        setIsOpenMessage(false)
        if (message.length > 0) {

            setIsOpenMessage(true)

        }



    };

    useEffect(() => {
        if (!login.ApenasVisualizacao) {
            let TipoFiltro = "PopUp"
            let dados = [];
            if (!Cookies.get('Notifications')) {
                fetchBanners().then(result => {
                    dados = result.data.Data;
                    if (dados != null) {
                        if (dados.Dados.length > 0) {
                            setMessage(result.data.Data.Dados.filter(dado => dado.TipoFiltro === TipoFiltro))
                            setIsOpenMessage(true)

                            Cookies.set('Notifications', true, { expires: new Date().getTime() + 60 * 60 * 1000 });
                        }
                    }
                })
            }
        }

    }, [])

    useEffect(() => {
        if (cookies._filialSelecionada) {
            let filialSelecionada = (cookies._filialSelecionada).toString();
            if (filialSelecionada) {
                loadData(filialSelecionada)
            }

            if (filialSelecionada === 2 || filialSelecionada === "2") {
                setMensagemBike(true)
            }
            else if (filialSelecionada === 6 || filialSelecionada === "6") {
                setMensagemFish(true)
            }
        }

    }, [cookies._filialSelecionada]);

    if (!getItemSession('_token') || !cookies._filialSelecionada) {
        window.location.href = "/login_vitrine"
    } else {
        if (cookies.LinkProduto) {
            var urlDirection = cookies.LinkProduto
            var urlProduto = cookies.LinkProduto.split('/');
            urlProduto = urlProduto.find(element => element === 'produto')
        }
        if (urlProduto === 'produto') {
            window.location.href = urlDirection
        }


    }
    useEffect(() => {
        const get = getItemSession('_dados');
        const dados = JSON.parse(get);
        if (dados.ApenasVisualizacao) {
            if (cookies.LinkOld) {
                window.location.href = '/logout'
            }
        }
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenFull, setIsOpenFull] = useState(false);
    const [promotions, setPromotions] = useState([]);
    const [releases, setReleases] = useState([]);
    const [ownBrands, setOwnBrands] = useState([]);
    const [bestSellers, setBestSellers] = useState([]);
    const [especiales, setEspeciales] = useState([]);

    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) },
        [promotions, especiales, releases, releases, ownBrands])


    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setReadMessage();
        setIsOpen(false);
    };

    const handleClearCart = () => {
        setIsOpenFull(true);
        setIsOpen(false);

        resetCart()
            .then(result => {
                setDados(result.data.Data);

                const newStorage = {
                    "QuantidadeRegistrosTotal": 0,
                    "QuantidadeRegistrosRetornados": 0,
                    "Paginas": 1,
                    "ResultadosPorPagina": 0,
                    "Dados": []
                }
                setItemSession('_carrinho', JSON.stringify(newStorage))
                refreshCartFn(newStorage);

                setReadMessage();
                setIsOpenFull(false);
            })
            .catch(reject => {
                disconnect();
            })
    }

    useEffect(() => {

        const getNumeroItens = () => {
            const get = getItemSession('_dados');
            const dados = JSON.parse(get);
            if (!dados.ApenasVisualizacao) {
                if (cookies.LinkOld) {
                    var OldLink = cookies.LinkOld
                    Cookies.remove('LinkOld');
                    window.location.href = OldLink
                }
            }
            return dados.NumeroItens;
        }

        const readMessage = getItemSession('_home_message');
        const numeroItens = getNumeroItens();

        if (numeroItens > 0 && readMessage !== "true") {
            showModal()
        }
    }, [])

    // useEffect(() => {
    //     fetchProductsByTagHome('Promocao', 6)
    //         .then(result => {
    //             if (result.data.Codigo === 200 && result.data.Data) {
    //                 setPromotions(result.data.Data.Dados)
    //             }
    //         })
    //     fetchProductsByTagHome('VITRINE1', 6)
    //         .then(result => {
    //             if (result.data.Codigo === 200 && result.data.Data) {
    //                 setEspeciales(result.data.Data.Dados)
    //             }
    //         })
    //     fetchProductsByTagHome('VITRINE2', 6)
    //         .then(result => {
    //             if (result.data.Codigo === 200 && result.data.Data) {
    //                 setReleases(result.data.Data.Dados)
    //             }
    //         })
    //     fetchProductsByTagHome('VITRINE3', 6)
    //         .then(result => {
    //             if (result.data.Codigo === 200 && result.data.Data) {
    //                 setOwnBrands(result.data.Data.Dados)
    //             }
    //         })
    //     fetchProductsByTagHome('VITRINE4', 6)
    //         .then(result => {
    //             if (result.data.Codigo === 200 && result.data.Data) {
    //                 setBestSellers(result.data.Data.Dados)
    //             }
    //         })
    // }, [])

    useEffect(() => {
        fetchTagVitrine()
            .then(result => {
                console.log(result)
                if (result.data.Codigo === 200 && result.data.Data) {
                    setTags(result.data.Data.Dados)
                }
            })
    }, [])



    const setDados = (novoUkPedido) => {
        setItemSession('_pedido', novoUkPedido);
    }

    const setReadMessage = () => {
        setItemSession('_home_message', true);
    }

    const loadData = (idFilial) => {
        var token = getItemSession('_token')
        fetchMudarFilialToken(idFilial, token).then(r => {
            setItemSession('_filialSelecionada', idFilial);
            Promise.all([
                fetchManufacturers(token),
                fetchCategories(token),
                fetchVersion(token),
                fetchHomolog(token),
                fetchCartItems(token),
                fetchCategoriesById(token, 2, true, true)
            ])
                .then(resultFetch => {
                    setItemSession('_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados));
                    setItemSession('_categorias', JSON.stringify(resultFetch[1].data.Data.Dados))
                    setItemSession('blink_versao', JSON.stringify(resultFetch[2].data.Data))
                    setItemSession('blink_homolog', JSON.stringify(resultFetch[3].data.Data))
                    setItemSession('_carrinho', JSON.stringify(resultFetch[4].data.Data))
                    setItemSession('_marcas', JSON.stringify(resultFetch[5].data.Data.Dados))

                    setCookie('_filialSelecionada', idFilial, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                })
        })
    }

    return (
        <>
            <CardBanner />
            {mensagemBike &&
                <section className="">
                    <div style={{ 'margin-left': '1.3%', 'margin-bottom': '1%', 'font-size': '18px' }} className="cards-container">
                        <p>{translations.Home?.infoMensagem} <a target='_blank' rel="noopener noreferrer" href='https://bike.shimano.com/es-AR' ><b>{translations.Home?.AQUI}</b></a>.</p>
                    </div>
                </section>
            }
            <Banner />
            {mensagemFish &&
                <section className="">
                    <div style={{ 'margin-left': '1.3%', 'margin-bottom': '1%', 'font-size': '18px' }} className="cards-container">
                        <p>{translations.Home?.infoMensagem} <a target='_blank' rel="noopener noreferrer" href='https://fish.shimano.com/es-AR' ><b>{translations.Home?.AQUI}</b></a>.</p>
                    </div>
                </section>
            }
            <TipBar />
            <Brands />
            <section className="conteudo_interno container">
                <div className="cards-container">
                    {tags.map((tag, index) => (
                        <React.Fragment key={tag.idTag || index}> {/* Use um identificador único, se disponível */}
                            <Release destaque={tag} />
                            {tag.idTag === 1 && <BannerAD />}
                        </React.Fragment>
                    ))}
                    {/* <Release destaque={releases} /> */}
                    {/* <OwnBrand destaque={ownBrands} /> */}
                </div>
            </section>
            <section className="conteudo_interno container">
                <div className="cards-container">
                    {/* <Especiales destaque={especiales} />
                    <BestSeller destaque={bestSellers} /> */}
                </div>
            </section>

            {/* <!-- Modal --> */}
            <Modal show={isOpen} className="modal modal-boleto fade">
                <Modal.Header>
                    <div className="modal-header_container">
                        <h2 className="titulo h2">{translations.Home?.Modal_boleto_Header}</h2>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { }}>
                        <FaWindowClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>{translations.Home?.Modal_Body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-primary btn-sm btn-modal-home" onClick={hideModal}>{translations.Home?.Modal_footer_btn_hide}</button>{' '}
                    <button className="btn-warning btn-sm btn-modal-home" onClick={handleClearCart}>{translations.Home?.Modal_footer_btn_Clear}</button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Modal Aviso Login --> */}
            {message.slice(0, 1).map((notification) => (
                <ModalBanner
                    isOpen={isOpenMessage}
                    style={customStyles}
                    key={notification.idBanner}
                    ariaHideApp={false}
                >

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => (showModalMessageOff(notification.idBanner))}>
                        <FaWindowClose />
                    </button>
                    {
                        notification.LinkRedirecionamento ?
                            <a href={notification.LinkRedirecionamento} target="_blank" rel="noopener noreferrer">
                                <div style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex' }}>
                                    <img style={{ borderRadius: '5px' }} src={notification.LinkImagem} alt="" />
                                </div>
                            </a>
                            :
                            <div style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex' }}>
                                <img style={{ borderRadius: '5px' }} src={notification.LinkImagem} alt="" />
                            </div>
                    }

                </ModalBanner>

            ))}
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Home);